import * as React from 'react';
import { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';

import Logo from './Logo';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
  return (
    <MenuItemLink
      sidebarIsOpen
      leftIcon={<SettingsIcon/>}
      primaryText="Configuration"
      ref={ref}
      to="/configuration"
      onClick={props.onClick}
    />
  );
});

const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>
    <ConfigurationMenu/>
  </UserMenu>
);

const CustomAppBar = (props: any) => {
  const classes = useStyles();
  return (
    <AppBar
      {...props}
      elevation={1}
      userMenu={<CustomUserMenu/>}
    >
      <Typography
        className={classes.title}
        color="inherit"
        id="react-admin-title"
        variant="h6"
      />
      <Logo/>
      <span className={classes.spacer}/>
    </AppBar>
  );
};

export default CustomAppBar;
