import { call } from 'redux-saga/effects';
import { makeApiRequest } from 'helpers/makeApiRequest';
import { Report, ExtendPeriodData } from 'extendReportsPeriod/types';

export function* getReportsListForExtend() {
  const { 
    json: {
      success,
      data,
    }
  }: { 
    json: {
      success: boolean;
      data: Report[];
    }
  } = yield call(makeApiRequest, { path: 'admin/extendReportPeriod' });

  if (!success) {
    throw new Error('Getting ncts list is not successful');
  }
  return data;
}

export function* extendReportsPeriod(data: ExtendPeriodData) {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
  };
  const { 
    json: { success, reports } }: { json: { success: boolean; reports?: string[] }
  } = yield call(makeApiRequest, { path: 'admin/extendReportPeriod', options });

  if (!success && !reports) {
    throw new Error('Unsuccessful request to extend reports period');
  }

  return { reports };
}
