import * as React from 'react';
import { Admin, Resource } from 'react-admin';

import jsonServerProvider from './dataProvider';
import config from './config';

import authProvider from './authProvider';
import { httpClient } from './httpClient';
import themeReducer from './themeReducer';
import { jobsReducer } from './jobs/reducer';
import { jobsSagas } from './jobs/sagas';
import { reportsForExtendReducer } from './extendReportsPeriod/reducer';
import { reportsForExtendSagas } from './extendReportsPeriod/sagas';
import { Login, Layout } from './layout';
// import { Dashboard } from './dashboard/Dashboard';
import { customRoutes } from './customRoutes';

import { resourceNames } from './constants';
import { companiesResource } from './companies';
import { doctorsResource } from './doctors';
import { reportsResource } from './reports';
import { usersResource } from './users';
import { jobsLogResource } from './jobsLog';

const { apiUrl } = config;

const dataProvider = jsonServerProvider(apiUrl, httpClient);

const customSagas = [...jobsSagas, ...reportsForExtendSagas];
const customReducers = { theme: themeReducer, jobs: jobsReducer, reportsForExtend: reportsForExtendReducer };

const App = () => {
  return (
    <Admin
      disableTelemetry
      authProvider={authProvider}
      customReducers={customReducers}
      customRoutes={customRoutes}
      customSagas={customSagas}
      // dashboard={Dashboard}
      dataProvider={dataProvider}
      layout={Layout}
      loginPage={Login}
      title=""
    >
      <Resource
        name={resourceNames.ADMIN_USERS}
        {...usersResource}
      />
      <Resource
        name={resourceNames.ADMIN_COMPANIES}
        {...companiesResource}
      />
      <Resource
        name={resourceNames.ADMIN_DOCTORS}
        {...doctorsResource}
      />
      <Resource
        name={resourceNames.ADMIN_REPORTS}
        {...reportsResource}
      />
      <Resource
        name={resourceNames.ADMIN_ROLES}
      />
      <Resource
        name={resourceNames.ADMIN_HEALTH_PROVIDER_DEPARTMENTS}
      />
      <Resource
        name={resourceNames.ADMIN_JOB_LOG}
        {...jobsLogResource}
      />
    </Admin>
  );
};

export default App;
