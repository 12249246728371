import * as React from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
} from 'react-admin';
import DoctorLinkField from './DoctorLinkField';

export const DoctorsList = (props: ListProps) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      perPage={25}
      sort={{ field: 'id', order: 'asc' }}
    >
      <Datagrid>
        <DoctorLinkField label='name' />
        <TextField source="id"/>
        <TextField source="email"/>
        <TextField source="first_name"/>
        <TextField source="last_name"/>
        <TextField source="clinic_name"/>
        <TextField source="is_verified"/>
        <TextField source="state"/>
      </Datagrid>
    </List>
  );
};
