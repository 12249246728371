import * as React from 'react';
import { Card as MuiCard, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOnOutlined';
import MailIcon from '@material-ui/icons/MailOutline';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import ImportContactsIcon from '@material-ui/icons/ImportContactsOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutlineOutlined';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import { FilterList, FilterListItem, FilterLiveSearch, useGetList } from 'react-admin';
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { resourceNames } from '../constants';

const Card = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const Aside = () => {
  const { data: companiesData } = useGetList(resourceNames.ADMIN_COMPANIES);
  const { data: reportsData } = useGetList(resourceNames.ADMIN_REPORTS);
  const { data: rolesData } = useGetList(resourceNames.ADMIN_ROLES);
  return (
    <Card>
      <CardContent>
        <FilterLiveSearch/>

        <FilterList
          icon={<AccessTimeIcon/>}
          label="Last visited"
        >
          <FilterListItem
            label="Today"
            value={{
              lastOnlineAtGte: endOfYesterday().toISOString(),
              lastOnlineAtLte: undefined,
            }}
          />
          <FilterListItem
            label="This week"
            value={{
              lastOnlineAtGte: startOfWeek(new Date()).toISOString(),
              lastOnlineAtLte: undefined,
            }}
          />
          <FilterListItem
            label="Last week"
            value={{
              lastOnlineAtGte: subWeeks(
                startOfWeek(new Date()),
                1
              ).toISOString(),
              lastOnlineAtLte: startOfWeek(new Date()).toISOString(),
            }}
          />
          <FilterListItem
            label="This month"
            value={{
              lastOnlineAtGte: startOfMonth(new Date()).toISOString(),
              lastOnlineAtLte: undefined,
            }}
          />
          <FilterListItem
            label="Last month"
            value={{
              lastOnlineAtGte: subMonths(
                startOfMonth(new Date()),
                1
              ).toISOString(),
              lastOnlineAtLte: startOfMonth(new Date()).toISOString(),
            }}
          />
          <FilterListItem
            label="Earlier"
            value={{
              lastOnlineAtGte: undefined,
              lastOnlineAtLte: subMonths(
                startOfMonth(new Date()),
                1
              ).toISOString(),
            }}
          />
        </FilterList>

        <FilterList
          icon={<MonetizationOnIcon/>}
          label="Has entered"
        >
          <FilterListItem
            label="Yes"
            value={{ hasActivated: true }}
          />
          <FilterListItem
            label="No"
            value={{ hasActivated: false }}
          />
        </FilterList>

        <FilterList
          icon={<MailIcon/>}
          label="Has invite url"
        >
          <FilterListItem
            label="Yes"
            value={{ hasTempToken: true }}
          />
          <FilterListItem
            label="No"
            value={{ hasTempToken: false }}
          />
        </FilterList>

        <FilterList
          icon={<AccessibilityIcon/>}
          label="Is active"
        >
          <FilterListItem
            label="Yes"
            value={{ isActive: true }}
          />
          <FilterListItem
            label="No"
            value={{ isActive: false }}
          />
        </FilterList>

        <FilterList
          icon={<LocalOfferIcon/>}
          label="Companies"
        >
          {Object.values(companiesData).map(({ id: companyId, name: companyName }) => (
            <FilterListItem
              key={companyId}
              label={companyName}
              value={{ companyId }}
            />
          ))}
        </FilterList>

        <FilterList
          icon={<ImportContactsIcon/>}
          label="Reports"
        >
          {Object.values(reportsData).map(({ id: reportId, nctId: reportName }) => (
            <FilterListItem
              key={reportId}
              label={reportName}
              value={{ reportId }}
            />
          ))}
        </FilterList>

        <FilterList
          icon={<PersonOutlineIcon/>}
          label="Roles"
        >
          {Object.values(rolesData).map(({ id: roleId, name: roleName }) => (
            <FilterListItem
              key={roleId}
              label={roleName}
              value={{ roleId }}
            />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default Aside;
