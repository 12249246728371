import { AuthProvider } from 'react-admin';

import { executeRecaptcha } from './helpers/recaptcha';
import { localStorageKeys } from './constants';

const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    const recaptchaToken = await executeRecaptcha('login');

    const request = new Request(process.env.REACT_APP_REPORT_SERVICE_API + '/sign-in', {
      method: 'POST',
      body: JSON.stringify({ email, password, rememberMe: false, recaptchaToken }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(async response => {
        const data = await response.json();
        if (response.status < 200 || response.status >= 300) {
          throw new Error(data.data || response.statusText);
        }
        return data;
      })
      .then(auth => {
        localStorage.setItem(localStorageKeys.ACCESS_TOKEN, auth.data.accessToken);
        localStorage.setItem(localStorageKeys.REFRESH_TOKEN, auth.data.refreshToken);
        localStorage.setItem(localStorageKeys.USERNAME, auth.data.user.name);
      })
      .catch((err) => {
        throw new Error(err.message || 'Network error');
      });
  },
  logout: () => {
    localStorage.removeItem(localStorageKeys.USERNAME);
    localStorage.removeItem(localStorageKeys.ACCESS_TOKEN);
    localStorage.removeItem(localStorageKeys.REFRESH_TOKEN);

    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 419 || status === 403) {
      localStorage.removeItem(localStorageKeys.USERNAME);
      localStorage.removeItem(localStorageKeys.ACCESS_TOKEN);
      localStorage.removeItem(localStorageKeys.REFRESH_TOKEN);
      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem(localStorageKeys.USERNAME) ? Promise.resolve() : Promise.reject(),
  getPermissions: () => Promise.reject('Unknown method'),
  getIdentity: () => {
    const name = localStorage.getItem(localStorageKeys.USERNAME) as string;
    return Promise.resolve({
      id: name,
      fullName: name,
      avatar: `https://eu.ui-avatars.com/api/?name=${name}?size=25`,
    });
  },
};

export default authProvider;
