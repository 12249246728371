import * as React from 'react';
import { Route } from 'react-router-dom';
import { Authenticated } from 'react-admin';
import { Configuration } from './configuration/Configuration';
import { Jobs } from './jobs';
import { ExtendReportsPeriod } from './extendReportsPeriod';

export const customRoutes = [
  <Route
    exact
    key="configuration"
    path="/configuration"
    render={() => (
      <Authenticated>
        <Configuration />
      </Authenticated>
    )}
  />,
  <Route
    exact
    key="jobs"
    path="/jobs"
    render={() => (
      <Authenticated>
        <Jobs/>
      </Authenticated>
    )}
  />,
  <Route
    exact
    key="extendReportsPeriod"
    path="/extendReportsPeriod"
    render={() => (
      <Authenticated>
        <ExtendReportsPeriod />
      </Authenticated>
    )}
  />,
];
