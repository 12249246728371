import { Paper, Container } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { AppState } from 'types';

const useStyles = makeStyles((theme: Theme) => ({
  errorList: {
    marginTop: theme.spacing(1),
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(1)}px`,
    border: `3px solid #f44336`,
    fontSize: 16,
  }
}));

export const NctIdsToBeExcluded = () => {
  const classes = useStyles();
  const { reportsForExclude } = useSelector(({ reportsForExtend }: AppState) => {
    return reportsForExtend.extendReportsPeriod;
  });

  if (!reportsForExclude.length) {
    return null;
  }

  return (
    <Paper className={classes.errorList}>
      <Container>
        Unable to extend the date for reports
        as their max contract date is already
        equal to or more than your selected date:
      </Container>
      <Container>
        {reportsForExclude.join(', ')}
      </Container>
    </Paper>
  );
};
