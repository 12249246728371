import { Reducer } from 'redux';
import { UNREGISTER_RESOURCE } from 'react-admin';
import { QueryStatuses } from '../constants';
import {
  FETCH_JOB_DISEASE_LIST_REQUEST,
  FETCH_JOB_DISEASE_LIST_SUCCESS,
  FETCH_JOB_DISEASE_LIST_FAILURE,
  FETCH_JOB_PARAMS_REQUEST,
  FETCH_JOB_PARAMS_SUCCESS,
  FETCH_JOB_PARAMS_FAILURE,
  CREATE_JOB_REQUEST,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_FAILURE,
  CHANGE_JOB_STATUS,
  CHANGE_GENERAL_JOB_STATUS,
  RESET_GENERAL_JOB_STATUS,
} from './actions';
import { Jobs } from './types';
import { JobStatuses } from './constants';
import { AnyAction } from 'redux';

type State = Jobs;

const {
  PRISTINE,
  PENDING,
  FAILURE,
  SUCCESS,
} = QueryStatuses;

const { PENDING: PENDING_JOB_STATUS } = JobStatuses;

const initState: State = {
  diseases: { status: PRISTINE, data: null, error: null },
  params: { status: PRISTINE, data: null, error: null },
  jobCreation: { status: PRISTINE, error: null },
  jobStatuses: [],
  generalStatus: null,
};

export const jobsReducer: Reducer<State, AnyAction> = (
  previousState = initState,
  action
) => {
  const { payload } = action;
  switch (action.type) {
    case UNREGISTER_RESOURCE:
      return initState;
    case FETCH_JOB_DISEASE_LIST_REQUEST:
      return { ...previousState, diseases: { ...previousState.diseases, status: PENDING } };
    case FETCH_JOB_DISEASE_LIST_SUCCESS:
      return { ...previousState, diseases: { status: SUCCESS, data: payload, error: null } };
    case FETCH_JOB_DISEASE_LIST_FAILURE:
      return { ...previousState, diseases: { status: FAILURE, data: null, error: payload } };
    case FETCH_JOB_PARAMS_REQUEST:
      return { ...previousState, params: { ...previousState.params, status: PENDING } };
    case FETCH_JOB_PARAMS_SUCCESS:
      return { ...previousState, params: { status: SUCCESS, data: payload, error: null } };
    case FETCH_JOB_PARAMS_FAILURE:
      return { ...previousState, params: { status: FAILURE, data: null, error: payload } };
    case CREATE_JOB_REQUEST:
      return { ...previousState, jobCreation: { ...previousState.jobCreation, status: PENDING } };
    case CREATE_JOB_SUCCESS:
      return {
        ...previousState,
        jobCreation: { status: SUCCESS, error: null },
        jobStatuses: [...previousState.jobStatuses, { ...payload, status: PENDING_JOB_STATUS }]
      };
    case CREATE_JOB_FAILURE:
      return { ...previousState, jobCreation: { status: FAILURE, error: payload } };
    case CHANGE_JOB_STATUS:
      return {
        ...previousState,
        jobStatuses: previousState.jobStatuses.map(i => {
          return i.id === payload.id ? { ...i, status: payload.status } : i;
        }),
      };
    case CHANGE_GENERAL_JOB_STATUS:
      return { ...previousState, generalStatus: payload };
    case RESET_GENERAL_JOB_STATUS:
      return { ...previousState, generalStatus: null };
    default:
      return previousState;
  }
};
