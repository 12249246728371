import { stringify } from 'query-string';
import { fetchUtils, DataProvider } from 'ra-core';
import { resourceNames } from './constants';

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 */
const dataProvider = (
  apiUrl: any,
  httpClient = fetchUtils.fetchJson,
  countHeader: string = 'Content-Range'
): DataProvider => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const options =
      countHeader === 'Content-Range'
        ? {
          // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
          headers: new Headers({
            Range: `${resource}=${rangeStart}-${rangeEnd}`,
          }),
        }
        : {};

    return httpClient(url, options).then(({ headers = new Headers(), json }) => {
      if (!headers.has(countHeader)) {
        throw new Error(
          `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
        );
      }
      let data = json;
      // Hack to provider
      if (resource === resourceNames.ADMIN_USERS) {
        data = json.map((item: any) => ({ ...item, id: item.userWixId }));
      }

      return {
        data,
        total:
          countHeader === 'Content-Range'
            ? parseInt(
              (headers.get('content-range') as any).split('/').pop(),
              10
            )
            : parseInt((headers.get(countHeader.toLowerCase()) as string)),
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
      let data = json;
      // Hack to provider
      if (resource === resourceNames.ADMIN_USERS) {
        data = { ...json, id: json.userWixId };
      }
      return {
        data
      };
    }),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => {
      let data = json;
      // Hack to provider
      if (resource === resourceNames.ADMIN_USERS) {
        data = json.map((item: any) => ({ ...item, id: item.userWixId }));
      }

      return { data };
    });
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const options =
      countHeader === 'Content-Range'
        ? {
          // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
          headers: new Headers({
            Range: `${resource}=${rangeStart}-${rangeEnd}`,
          }),
        }
        : {};

    return httpClient(url, options).then(({ headers = new Headers(), json }) => {
      if (!headers.has(countHeader)) {
        throw new Error(
          `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
        );
      }
      let data = json;
      // Hack to provider
      if (resource === resourceNames.ADMIN_USERS) {
        data = json.map((item: any) => ({ ...item, id: item.userWixId }));
      }
      return {
        data,
        total:
          countHeader === 'Content-Range'
            ? parseInt(
              (headers.get('content-range') as any).split('/').pop(),
              10
            )
            : parseInt((headers.get(countHeader.toLowerCase()) as string)),
      };
    });
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      let data = json;
      // Hack to provider
      if (resource === resourceNames.ADMIN_USERS) {
        data = { ...json, id: json.userWixId };
      }
      return {
        data
      };
    }),

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })
      )
    ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      let data = { ...params.data, id: json.id };
      // Hack to provider
      if (resource === resourceNames.ADMIN_USERS) {
        data = { ...params.data, id: json.userWixId };
      }
      return {
        data,
      };
    }),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    }).then(({ json }) => {
      let data = json;
      // Hack to provider
      if (resource === resourceNames.ADMIN_USERS) {
        data = { ...json, id: json.userWixId };
      }
      return {
        data
      };
    }),

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'text/plain',
          }),
        })
      )
    ).then(responses => ({
      data: responses.map(({ json }) => json.id),
    })),
});

export default dataProvider;
