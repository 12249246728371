import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import classnames from 'classnames';
import {
  // DashboardMenuItem,
  MenuItemLink,
  MenuProps,
} from 'react-admin';

import { resourceNames } from '../constants';
import { usersResource } from '../users';
import { companiesResource } from '../companies';
import { reportsResource } from '../reports';
import { jobsLogResource } from '../jobsLog';
import { Icon as JobsIcon } from '../jobs';
import SubMenu from './SubMenu';
import { AppState } from '../types';

type MenuName = 'menuReporting';

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuReporting: true,
  });
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  // eslint-disable-next-line @typescript-eslint/no-shadow
  useSelector((state: AppState) => state.theme); // force rerender on theme change
  const classes = useStyles();

  const handleToggle = (menu: MenuName) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      {' '}
      {/* <DashboardMenuItem/> */}
      <MenuItemLink
        dense={dense}
        leftIcon={<usersResource.icon/>}
        primaryText="Users"
        to={{
          pathname: `/${resourceNames.ADMIN_USERS}`,
          state: { _scrollToTop: true },
        }}
      />
      <MenuItemLink
        dense={dense}
        leftIcon={<companiesResource.icon/>}
        primaryText="Companies"
        to={{
          pathname: `/${resourceNames.ADMIN_COMPANIES}`,
          state: { _scrollToTop: true },
        }}
      />
      <MenuItemLink
        dense={dense}
        leftIcon={<companiesResource.icon/>}
        primaryText="Doctors"
        to={{
          pathname: `/${resourceNames.ADMIN_DOCTORS}`,
          state: { _scrollToTop: true },
        }}
      />
      <MenuItemLink
        dense={dense}
        leftIcon={<reportsResource.icon/>}
        primaryText="Reports"
        to={{
          pathname: `/${resourceNames.ADMIN_REPORTS}`,
          state: { _scrollToTop: true },
        }}
      />
      <MenuItemLink
        dense={dense}
        leftIcon={<JobsIcon/>}
        primaryText="Automations"
        to={{
          pathname: '/jobs',
          state: { _scrollToTop: true },
        }}
      />
      <MenuItemLink
        dense={dense}
        leftIcon={<jobsLogResource.icon/>}
        primaryText="Automation logs"
        to={{
          pathname: `/${resourceNames.ADMIN_JOB_LOG}`,
          state: { _scrollToTop: true },
        }}
      />
      <SubMenu
        dense={dense}
        handleToggle={() => handleToggle('menuReporting')}
        icon={<LibraryBooksIcon/>}
        isOpen={state.menuReporting}
        name="Reporting"
      >
        <MenuItemLink
          dense={dense}
          leftIcon={<LibraryBooksIcon/>}
          primaryText="Reports"
          to={{
            pathname: `/${resourceNames.ADMIN_USAGE_REPORTS}`,
            state: { _scrollToTop: true },
          }}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<LibraryBooksIcon/>}
          primaryText="Usage statistics"
          to={{
            pathname: `/${resourceNames.ADMIN_USAGE_STATISTICS}`,
            state: { _scrollToTop: true },
          }}
        />
      </SubMenu>
      <MenuItemLink
        dense={dense}
        leftIcon={<reportsResource.icon/>}
        primaryText="Extend reports period"
        to={{
          pathname: `/extendReportsPeriod`,
          state: { _scrollToTop: true },
        }}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 250,
  },
  closed: {
    width: 55,
  },
}));

export default Menu;
