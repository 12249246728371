/**
 * Converts camel case to human readable
 * @param job
 */
export const formatJobToDisplay = (job: string = '') => {
  const jobInLowerCase = job.toLowerCase();
  return job.split('')
    .map((letter, index) => {
      if (index === 0) {
        return letter.toUpperCase();
      }
      return letter === jobInLowerCase[index] ? letter : ' ' + jobInLowerCase[index];
    })
    .join('');
};
