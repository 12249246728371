import configs from '../config';

export const executeRecaptcha = async (action: string) => {
  const { recaptchaSiteKey } = configs;
  if (!recaptchaSiteKey) {
    return null;
  }
  try {
    const { grecaptcha } = window;
    return await grecaptcha?.execute(recaptchaSiteKey, { action });
  } catch {
    return null;
  }
};
