import { fetchUtils } from 'react-admin';
import { localStorageKeys } from './constants';

export const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem(localStorageKeys.ACCESS_TOKEN);
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};
