export const localStorageKeys = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  USERNAME: 'username',
};


export const resourceNames = {
  ADMIN_USERS: 'admin/users',
  ADMIN_COMPANIES: 'admin/companies',
  ADMIN_DOCTORS: 'admin/doctors',
  ADMIN_REPORTS: 'admin/reports',
  ADMIN_ROLES: 'admin/roles',
  ADMIN_USAGE_REPORTS: 'admin/usageReports',
  ADMIN_USAGE_STATISTICS: 'admin/usageStatistics',
  ADMIN_HEALTH_PROVIDER_DEPARTMENTS: 'admin/healthProviderDepartments',
  ADMIN_JOB_LOG: 'admin/job/log',
};

export enum QueryStatuses {
    PRISTINE = 'PRISTINE_QUERY_STATUS',
    PENDING = 'PENDING_QUERY_STATUS',
    FAILURE = 'FAILURE_QUERY_STATUS',
    SUCCESS = 'SUCCESS_QUERY_STATUS',
}
