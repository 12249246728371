import { ExtendPeriodData } from 'extendReportsPeriod/types';

export const FETCH_REPORTS_FOR_EXTEND = 'REPORTS_FOR_EXTEND';
export const FETCH_REPORTS_FOR_EXTEND_LIST_REQUEST = 'FETCH_REPORTS_FOR_EXTEND_LIST_REQUEST';
export const FETCH_REPORTS_FOR_EXTEND_LIST_SUCCESS = 'FETCH_REPORTS_FOR_EXTEND_LIST_SUCCESS';
export const FETCH_REPORTS_FOR_EXTEND_LIST_FAILURE = 'FETCH_REPORTS_FOR_EXTEND_LIST_FAILURE';

export const EXTEND_REPORTS_PERIOD = 'EXTEND_REPORTS_PERIOD';
export const EXTEND_REPORTS_PERIOD_REQUEST = 'EXTEND_REPORTS_PERIOD_REQUEST';
export const EXTEND_REPORTS_PERIOD_SUCCESS = 'EXTEND_REPORTS_PERIOD_SUCCESS';
export const EXTEND_REPORTS_PERIOD_FAILURE = 'EXTEND_REPORTS_PERIOD_FAILURE';
export const EXTEND_REPORTS_PERIOD_INTERRUPTED = 'EXTEND_REPORTS_PERIOD_INTERRUPTED';

export const SET_SELECTED_NCTS_FOR_EXTEND_REPORTS = 'SET_SELECTED_NCTS_FOR_EXTEND_REPORTS';
export const CLEAR_SELECTED_NCTS_FOR_EXTEND_REPORT = 'CLEAR_SELECTED_NCTS_FOR_EXTEND_REPORT';

export const fetchReportsForExtend = () => {
  return { type: FETCH_REPORTS_FOR_EXTEND };
};

export const fetchReportsForExtendRequest = () => {
  return { type: FETCH_REPORTS_FOR_EXTEND_LIST_REQUEST };
};

export const fetchReportsForExtendSuccess = (data: {}) => {
  return { type: FETCH_REPORTS_FOR_EXTEND_LIST_SUCCESS, payload: data };
};

export const fetchReportsForExtendFailure = (error: any) => {
  return { type: FETCH_REPORTS_FOR_EXTEND_LIST_FAILURE, payload: error };
};

export const extendReportsPeriod = (payload: ExtendPeriodData) => {
  return { type: EXTEND_REPORTS_PERIOD, payload };
};

export const extendReportsPeriodRequest = () => {
  return { type: EXTEND_REPORTS_PERIOD_REQUEST };
};

export const extendReportsPeriodSuccess = () => {
  return { type: EXTEND_REPORTS_PERIOD_SUCCESS };
};

export const extendReportsPeriodFailure = (error: any) => {
  return { type: EXTEND_REPORTS_PERIOD_FAILURE, payload: error };
};

export const extendReportsPeriodInterrupted = (data: string[]) => {
  return { type: EXTEND_REPORTS_PERIOD_INTERRUPTED, payload: data };
};

export const clearNctsForExtendReport = () => {
  return { type: CLEAR_SELECTED_NCTS_FOR_EXTEND_REPORT };
};
