import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableSortLabel,
  TableRow,
  TableCell,
  TablePagination,
  Checkbox,
  TextField,
  Box,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AppState } from 'types';
import { SET_SELECTED_NCTS_FOR_EXTEND_REPORTS } from './actions';

enum OrderEnum {
  ASC = 'asc',
  DESC = 'desc',
}

const descendingComparator = (
  a: { [x: string]: number; },
  b: { [x: string]: number; },
  orderBy: any
) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order: OrderEnum, orderBy: any) => {
  return order === OrderEnum.DESC
    ? (a: { [x: string]: number; }, b: { [x: string]: number; }) => descendingComparator(a, b, orderBy)
    : (a: { [x: string]: number; }, b: { [x: string]: number; }) => -descendingComparator(a, b, orderBy);
};

const stableSort = (
  array: any[],
  comparator: {
    (a: { [x: string]: number; }, b: { [x: string]: number; }): number; 
    (arg0: any, arg1: any): any; 
  }
) => {
  return array.map((el, index) => [el, index])
    .sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    })
    .map((el) => el[0]);
};

const headCells = [
  {
    id: 'nctNumber',
    numeric: false,
    disablePadding: true,
    label: 'Nct id',
    disableOrder: true,
  },
  { id: 'companyName', numeric: true, disablePadding: false, label: 'Companies name' },
];

const EnhancedTableHead = ({
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}: {
  classes: any,
  onSelectAllClick: any,
  order: OrderEnum,
  orderBy: string,
  numSelected: number,
  rowCount: number,
  onRequestSort: Function;
}) => {
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={rowCount > 0 && numSelected === rowCount}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            inputProps={{ "aria-label": 'select all desserts' }}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            align={headCell.numeric ? 'right' : 'left'}
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {
              !headCell.disableOrder ? 
                <TableSortLabel
                  active={!headCell.disableOrder && orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : OrderEnum.ASC}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id && (
                    <span className={classes.visuallyHidden}>
                      {order === OrderEnum.DESC ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  )}
                </TableSortLabel>
                : headCell.label
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    position: 'absolute',
    top: 20,
    height: 1,
    width: 1,
    margin: -1,
    padding: 0,
    border: 0,
    clip: 'rect(0 0 0 0)',
    overflow: 'hidden',
  }
}));

export const ReportsTable = () => {
  const { 
    data,
    selectedReportIds,
  } = useSelector(({ reportsForExtend }: AppState) => ({
    data: reportsForExtend.reports.data,
    selectedReportIds: reportsForExtend.selectedReportIds,
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchField, setSearchField] = useState('');
  const [order, setOrder] = useState<OrderEnum>(OrderEnum.ASC);
  const [orderBy, setOrderBy] = useState('companyName');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === OrderEnum.ASC;
    setOrder(isAsc ? OrderEnum.DESC : OrderEnum.ASC);
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: Array<string> = data.map(({ reportId }) => reportId);
      dispatch({
        type: SET_SELECTED_NCTS_FOR_EXTEND_REPORTS,
        payload: newSelecteds,
      });
      return;
    }
    dispatch({
      type: SET_SELECTED_NCTS_FOR_EXTEND_REPORTS,
      payload: [],
    });
  };

  const toggleReportSelect = (reportId: string) => {
    const selectedIndex = selectedReportIds.indexOf(reportId);
    let newSelected: Array<string> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedReportIds, reportId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedReportIds.slice(1));
    } else if (selectedIndex === selectedReportIds.length - 1) {
      newSelected = newSelected.concat(selectedReportIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedReportIds.slice(0, selectedIndex),
        selectedReportIds.slice(selectedIndex + 1),
      );
    }

    dispatch({
      type: SET_SELECTED_NCTS_FOR_EXTEND_REPORTS,
      payload: newSelected,
    });
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchField(event.target.value);
  };

  const isSelected = (name: string) => selectedReportIds.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <>
      <Box>
        <TextField
          fullWidth
          id="outlined-full-width"
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          placeholder="Find trial report"
          value={searchField}
          variant="outlined"
          onChange={handleSearchField}
        />
      </Box>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            aria-label="enhanced table"
            aria-labelledby="tableTitle"
            className={classes.table}
            size="medium"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selectedReportIds.length}
              order={order}
              orderBy={orderBy}
              rowCount={data.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {
                stableSort(data, getComparator(order, orderBy))
                  .filter(row => row.nctNumber.toLowerCase().includes(searchField.toLowerCase()))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => {
                    const { reportId, nctNumber, companyName } = row;
                    const isItemSelected = isSelected(reportId);
                    const labelId = `enhanced-table-checkbox-${reportId}`;

                    return (
                      <TableRow
                        hover
                        aria-checked={isItemSelected}
                        key={reportId}
                        role="checkbox"
                        selected={isItemSelected}
                        tabIndex={-1}
                        onClick={() => toggleReportSelect(reportId)}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          padding="none"
                          scope="row"
                        >
                          {nctNumber}
                        </TableCell>
                        <TableCell align="right">
                          {companyName}
                        </TableCell>
                      </TableRow>
                    );
                  })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={data.length}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>
    </>
  );
};
