import { useCallback } from 'react';
import { Field, withTypes } from 'react-final-form';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  FormHelperText,
  CircularProgress,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatJobToDisplay } from '../helpers/formatJobToDisplay';

interface FormValues {
  diseases?: string[];
  job?: string;
}

interface Props {
  diseaseList: {value: string; label: string}[];
  jobList: string[];
  disabled: boolean;
  loading: boolean;
  onSubmit: (values: Required<FormValues>) => void;
}

const useStyles = makeStyles((theme) => ({
  diseasesFormGroup: {
    flexDirection: 'row',
    marginTop: theme.spacing(1),
  },
  submitButton: {
    marginTop: theme.spacing(3),
  },
  jobSelect: {
    minWidth: 170,
    marginBottom: theme.spacing(3),
  },
}));

const { Form } = withTypes<FormValues>();

const renderCheckboxInput = ({
  input: { ...inputProps },
  ...props
}) => {
  return (
    <Checkbox
      size="small"
      {...inputProps}
      {...props}
    />
  );
};

const CheckboxField = ({ disabled = false, value, name }: {disabled?: boolean; value?: string; name: string}) => {
  return (
    <Field
      // @ts-ignore
      component={renderCheckboxInput}
      disabled={disabled}
      name={name}
      type="checkbox"
      value={value}
    />
  );
};

const SelectJobFormControl = ({ disabled = false, options }: {
  disabled?: boolean;
  options: string[];
}) => {
  const labelId = 'createJobFormJobSelect';
  const classes = useStyles();

  const renderSelectInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
  }) => {
    return (
      <FormControl
        required
        classes={{ root: classes.jobSelect }}
        component="fieldset"
        error={!!(touched && error)}
      >
        <InputLabel id={labelId}>
          Select Automation
        </InputLabel>
        <Select
          labelId={labelId}
          {...inputProps}
          {...props}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              value={option}
            >
              {formatJobToDisplay(option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  return (
    <Field
      // @ts-ignore
      component={renderSelectInput}
      disabled={disabled}
      name="job"
    />
  );
};

export const CreateJobForm = (props: Props) => {
  const classes = useStyles();

  const { diseaseList, loading, jobList, onSubmit, disabled } = props;

  const validate = useCallback((values: FormValues) => {
    const errors: FormValues = {};
    if (!Array.isArray(values.diseases) || !values.diseases.length) {
      errors.diseases = ['required'];
    }
    if (!values.job) {
      errors.job = 'required';
    }
    return errors;
  }, []);

  const onFormSubmit = useCallback((values: FormValues) => {
    if (disabled) {
      return;
    }
    onSubmit(values as Required<FormValues>);
  }, [onSubmit, disabled]);


  return (
    <Form
      render={({ handleSubmit, errors, touched }) => {
        return (
          <form
            noValidate
            onSubmit={handleSubmit}
          >
            <div>
              <SelectJobFormControl options={jobList} />
            </div>
            <div>
              <FormControl
                required
                component="fieldset"
              >
                <FormLabel component="legend">Pick condition(s)</FormLabel>
                <FormGroup classes={{ root: classes.diseasesFormGroup }}>
                  {diseaseList.map(({ value, label }) => (
                    <FormControlLabel
                      control={(
                        <CheckboxField
                          name="diseases"
                          value={value}
                        />
                      )}
                      key={value}
                      label={label}
                    />
                  ))}
                </FormGroup>
                <FormHelperText error>
                  {touched?.diseases && errors?.diseases ? errors?.diseases : null}
                </FormHelperText>
              </FormControl>
            </div>

            <Button
              classes={{ root: classes.submitButton }}
              color="primary"
              disabled={disabled}
              type="submit"
              variant="contained"
            >
              {loading && (
                <CircularProgress
                  size={25}
                  thickness={2}
                />
              )}
              start
            </Button>
          </form>
        );
      }}
      validate={validate}
      onSubmit={onFormSubmit}
    />
  );
};
