import Icon from '@material-ui/icons/LibraryBooks';

import { ReportsList } from './ReportsList';
import { ReportEdit } from './ReportEdit';

export const reportsResource = {
  list: ReportsList,
  edit: ReportEdit,
  icon: Icon,
};
