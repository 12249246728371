import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ReportsTable } from 'extendReportsPeriod/ReportsTable';
import { ExtendPeriodModal } from 'extendReportsPeriod/ExtendPeriodModal';
import { NctIdsToBeExcluded } from 'extendReportsPeriod/NctIdsToBeExcluded';
import { fetchReportsForExtend } from 'extendReportsPeriod/actions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    fontSize: 24,
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(1)}px`,
  },
}));

export const ExtendReportsPeriod = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchReportsForExtend());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <ReportsTable />
      <ExtendPeriodModal />
      <NctIdsToBeExcluded />
    </div>
  );
};
