import * as React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { FieldProps } from 'react-admin';
import { User } from '../types';

interface Props extends FieldProps<User> {
  className?: string;
  size?: string;
}

const AvatarField = ({ record, size = '25', className }: Props) =>
  record ? (
    <Avatar
      className={className}
      src={`https://eu.ui-avatars.com/api/?name=${record.name}?size=${size}x${size}`}
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
    />
  ) : null;

export default AvatarField;
