import { call, put, takeLeading } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import { getReportsListForExtend, extendReportsPeriod } from 'extendReportsPeriod/api';
import { ExtendPeriodData, Report } from 'extendReportsPeriod/types';
import {
  FETCH_REPORTS_FOR_EXTEND,
  EXTEND_REPORTS_PERIOD,
  CLEAR_SELECTED_NCTS_FOR_EXTEND_REPORT,
  fetchReportsForExtendRequest,
  fetchReportsForExtendSuccess,
  fetchReportsForExtendFailure,
  extendReportsPeriodRequest,
  extendReportsPeriodSuccess,
  extendReportsPeriodFailure,
  extendReportsPeriodInterrupted,
} from 'extendReportsPeriod/actions';

function* fetchReportsForExtendSaga() {
  yield takeLeading(FETCH_REPORTS_FOR_EXTEND, function* () {
    yield put(fetchReportsForExtendRequest());
    try {
      const result: Report[] = yield call(getReportsListForExtend);

      yield put(fetchReportsForExtendSuccess(result));
    } catch (err) {
      yield put(fetchReportsForExtendFailure(err));
      yield put(showNotification('Fetching reports for extend list failure', 'error'));
    }
  });
}

function* extendReportsPeriodSaga() {
  yield takeLeading(EXTEND_REPORTS_PERIOD, function* (
    { payload }: {
      type: typeof EXTEND_REPORTS_PERIOD;
      payload: ExtendPeriodData;
    }
  ) {
    yield put(extendReportsPeriodRequest());
    try {
      const { reports }: { reports: string[] } = yield call(extendReportsPeriod, payload);

      if (reports) {
        yield put(extendReportsPeriodInterrupted(reports));
      } else {
        yield put(extendReportsPeriodSuccess());
        yield put(showNotification('Extend reports successfully completed'));
      }
    } catch (err) {
      yield put(extendReportsPeriodFailure(err));
      yield put(showNotification(`${err}`, 'error'));
    }

    yield put({
      type: CLEAR_SELECTED_NCTS_FOR_EXTEND_REPORT
    });
  });
}

export const reportsForExtendSagas = [
  fetchReportsForExtendSaga,
  extendReportsPeriodSaga,
];
