import * as React from 'react';
import { Record } from 'ra-core';
import { startOfWeek } from 'date-fns';
import {
  List,
  ListProps,
  Datagrid,
  DateField,
  FunctionField
} from 'react-admin';
import JobsLogListAside from './JobsLogListAside';
import { formatJobToDisplay } from 'helpers/formatJobToDisplay';

interface JobsLog extends Record  {
  name?: string;
  createdAt?: Date;
  finishedAt?: Date;
  diseases?: string[];
}

export const JobsLogList = (props: ListProps) => {
  return (
    <List
      {...props}
      aside={<JobsLogListAside />}
      bulkActionButtons={false}
      filterDefaultValues={{ dateFrom: startOfWeek(new Date()).toISOString(), dateTo: undefined }}
      sort={{ field: 'createdAt', order: 'desc' }}
    >
      <Datagrid>
        <FunctionField
          render={(record: JobsLog | undefined) => formatJobToDisplay(record?.name)}
          source="name"
        />
        <DateField
          showTime
          sortable
          label="Started at"
          source="createdAt"
        />
        <DateField
          showTime
          sortable
          label="Finished at"
          source="finishedAt"
        />
        <FunctionField
          label="Diseases"
          render={(record: JobsLog | undefined) => record ? record.diseases?.join(', ') : null}
          sortable={false}
          source="diseases"
        />
      </Datagrid>
    </List>
  );
};
