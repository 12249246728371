import * as React from 'react';
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  required,
  email,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
  FormDataConsumer,
} from 'react-admin';
import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { resourceNames } from '../constants';
import { UserFormSectionTitle } from './UserFormSectionTitle';

export const styles: Styles<Theme, any> = {
  password: { display: 'inline-block' },
  confirmPassword: { display: 'inline-block', marginLeft: 32 },
  rolesIdsInput: { minWidth: '200px' },
};

const rolesIdsDefaultValue = [
  '64763bf8-3be8-4646-bff7-cd9b2427d0a8',
  'e7391942-6f72-448f-b4bf-d8eca027601d',
  '1a0b2816-f6d4-49d9-b929-1745cb792856',
  'bf08e739-5b62-4171-babc-a08a929fea19',
];

const useStyles = makeStyles(styles);

export const UserCreate = (props: CreateProps) => {
  const classes = useStyles(props);

  return (
    <Create {...props}>
      <SimpleForm>
        <UserFormSectionTitle label="Identity"/>
        <TextInput
          autoFocus
          source="name"
          validate={requiredValidate}
        />
        <TextInput
          inputProps={{ autoComplete: 'new-email' }}
          source="email"
          type="email"
          validate={[required(), email()]}
          validation={{ email: true }}
        />

        <UserFormSectionTitle label="Roles"/>
        <Box display='block'>
          <ReferenceArrayInput
            defaultValue={rolesIdsDefaultValue}
            reference={resourceNames.ADMIN_ROLES}
            source="rolesIds"
          >
            <SelectArrayInput
              className={classes.rolesIdsInput}
              formClassName={classes.rolesIdsInput}
              optionText="name"
              validate={requiredValidate}
            >
              <ChipField source="name"/>
            </SelectArrayInput>
          </ReferenceArrayInput>
        </Box>

        <UserFormSectionTitle label="Company"/>
        <ReferenceInput
          reference={resourceNames.ADMIN_COMPANIES}
          source="companyId"
          validate={requiredValidate}
        >
          <SelectInput optionText="name"/>
        </ReferenceInput>

        <UserFormSectionTitle label="Reports"/>
        <FormDataConsumer>
          {({ formData }) => (
            <ReferenceArrayInput
              fullWidth
              filter={{ companyId: formData.companyId }}
              reference={resourceNames.ADMIN_REPORTS}
              source="reportIds"
            >
              <SelectArrayInput
                fullWidth
                optionText="nctId"
              >
                <ChipField source="nctId"/>
              </SelectArrayInput>
            </ReferenceArrayInput>
          )}
        </FormDataConsumer>

        <UserFormSectionTitle label="Sources"/>
        <FormDataConsumer>
          {({ formData }) => (
            <ReferenceArrayInput
              fullWidth
              filter={{ companyId: formData.companyId }}
              reference={resourceNames.ADMIN_HEALTH_PROVIDER_DEPARTMENTS}
              source="healthProviderDepartmentIds"
            >
              <SelectArrayInput
                fullWidth
                optionText="name"
              >
                <ChipField source="id"/>
              </SelectArrayInput>
            </ReferenceArrayInput>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];
