import * as React from 'react';
import PropTypes from 'prop-types';
import {
  DateField,
  Record,
} from 'react-admin';
import {
  Typography,
  Card,
  CardContent,
  Box,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { makeStyles } from '@material-ui/core/styles';

const useAsideStyles = makeStyles(theme => ({
  root: {
    width: 400,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

interface AsideProps {
  record?: Record;
}

const Aside = ({ record }: AsideProps) => {
  const classes = useAsideStyles();
  return (
    <div className={classes.root}>
      {record && (
        <EventList record={record}/>
      )}
    </div>
  );
};

Aside.propTypes = {
  record: PropTypes.any,
};

interface EventListProps {
  record?: Record;
}

const EventList = ({ record }: EventListProps) => {
  return (
    <>
      <Box m="0 0 1em 1em">
        <Card>
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
            >
              history
            </Typography>
            <Box display="flex">
              <Box flexGrow={1}>
                <Box
                  display="flex"
                  mb="1em"
                >
                  <Box mr="1em">
                    <AccessTimeIcon
                      color="disabled"
                      fontSize="small"
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>
                      Created at
                    </Typography>
                    <DateField
                      record={record}
                      source="createdAt"
                    />
                  </Box>
                </Box>
              </Box>
              <Box flexGrow={1}>
                <Box
                  display="flex"
                  mb="1em"
                >
                  <Box mr="1em">
                    <AccessTimeIcon
                      color="disabled"
                      fontSize="small"
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>
                      Activated
                    </Typography>
                    <DateField
                      record={record}
                      source="activatedAt"
                    />
                  </Box>
                </Box>

              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default Aside;
