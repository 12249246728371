import * as React from 'react';
import { SelectInput, InputProps, useGetList } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { resourceNames } from '../constants';

const useStyles = makeStyles({
  input: { width: 150 },
});

interface Props extends Omit<InputProps, 'source'> {}

export const CompanyInput = (props: Props) => {
  const classes = useStyles();
  const { data: companiesData } = useGetList(resourceNames.ADMIN_COMPANIES);
  return (
    <SelectInput
      choices={Object.values(companiesData).map(({ id: companyId, name: companyName }) => ({
        id: companyId,
        name: companyName,
      }))}
      className={classes.input}
      source="companyId"
      {...props}
    />
  );
};
