import * as React from 'react';
import { Card as MuiCard, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { FilterList, FilterListItem } from 'react-admin';
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
} from 'date-fns';

const Card = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const Aside = () => {
  return (
    <Card>
      <CardContent>
        <FilterList
          icon={<AccessTimeIcon/>}
          label="Started"
        >
          <FilterListItem
            label="Today"
            value={{
              dateFrom: endOfYesterday().toISOString(),
              dateTo: undefined,
            }}
          />
          <FilterListItem
            label="This week"
            value={{
              dateFrom: startOfWeek(new Date()).toISOString(),
              dateTo: undefined,
            }}
          />
          <FilterListItem
            label="Last week"
            value={{
              dateFrom: subWeeks(
                startOfWeek(new Date()),
                1
              ).toISOString(),
              dateTo: startOfWeek(new Date()).toISOString(),
            }}
          />
          <FilterListItem
            label="This month"
            value={{
              dateFrom: startOfMonth(new Date()).toISOString(),
              dateTo: undefined,
            }}
          />
          <FilterListItem
            label="Last month"
            value={{
              dateFrom: subMonths(startOfMonth(new Date()),1).toISOString(),
              dateTo: startOfMonth(new Date()).toISOString(),
            }}
          />
          <FilterListItem
            label="Earlier"
            value={{
              dateFrom: undefined,
              dateTo: subMonths(
                startOfMonth(new Date()),
                1
              ).toISOString(),
            }}
          />
        </FilterList>

      </CardContent>
    </Card>
  );
};

export default Aside;
