import * as React from 'react';
import {
  Datagrid,
  DateField,
  DateInput,
  List,
  ListProps,
  NullableBooleanInput,
  TextField,
  ReferenceField,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
  SearchInput,
} from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';
import { CompanyInput } from './CompanyInput';
import UserLinkField from './UserLinkField';
import VisitorListAside from './UserListAside';
import { ReactElement } from 'react';
import { resourceNames } from '../constants';

const visitorFilters = [
  <SearchInput
    alwaysOn
    key="search"
    source="q"
  />,
  <DateInput
    key="dateInput"
    label="Last online later than"
    source="lastOnlineAtGte"
  />,
  <NullableBooleanInput
    key="hasActivated"
    source="hasActivated"
  />,
  <NullableBooleanInput
    defaultValue
    key="hasTempToken"
    label="Has invite url"
    source="hasTempToken"
  />,
  <CompanyInput key="companyId"/>,
];

const UserList = (props: ListProps): ReactElement => {
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  return (
    <List
      {...props}
      aside={<VisitorListAside/>}
      bulkActionButtons={false}
      filters={isSmall ? visitorFilters : undefined}
      perPage={25}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid
        optimized
        rowClick="edit"
      >
        <UserLinkField label="Name"/>
        <DateField
          showTime
          sortable
          label="Created at"
          source="createdAt"
        />
        <DateField
          showTime
          sortable
          label="Activated at"
          source="activatedAt"
        />
        <DateField
          showTime
          label="Updated at"
          source="updatedAt"
        />
        <ReferenceField
          reference={resourceNames.ADMIN_COMPANIES}
          source="companyId"
        >
          <TextField
            source="name"
          />
        </ReferenceField>
        <ReferenceArrayField
          label="Reports"
          reference={resourceNames.ADMIN_REPORTS}
          sortable={false}
          source="reportIds"
        >
          <SingleFieldList>
            <ChipField source="nctId"/>
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField
          reference={resourceNames.ADMIN_ROLES}
          sortable={false}
          source="rolesIds"
        >
          <SingleFieldList>
            <ChipField source="name"/>
          </SingleFieldList>
        </ReferenceArrayField>
      </Datagrid>
    </List>
  );
};

export default UserList;
