import LabelIcon from '@material-ui/icons/Label';
import { DoctorEdit } from './DoctorEdit';

import { DoctorsList } from './DoctorsList';

export const doctorsResource = {
  list: DoctorsList,
  edit: DoctorEdit,
  icon: LabelIcon,
};
