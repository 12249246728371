import * as React from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  ImageField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  company: {
    '& img': {
      display: 'table-cell',
      height: 50,
    }
  },
});

export const CompaniesList = (props: ListProps) => {
  const classes = useStyles();
  return (
    <List
      {...props}
      bulkActionButtons={false}
      perPage={25}
      sort={{ field: 'id', order: 'asc' }}
    >
      <Datagrid>
        <TextField source="id"/>
        <TextField source="name"/>
        <ImageField
          cellClassName={classes.company}
          className={classes.company}
          source="companyIconUrl"
          title="name"
        />
      </Datagrid>
    </List>
  );
};
