import { call } from 'redux-saga/effects';
import { makeApiRequest } from 'helpers/makeApiRequest';
import { CreateJobParams, Disease, JobParams, JobStatus } from './types';

export function* getDiseaseList () {
  const { json: { success, data } }: { json: { success: boolean; data: Disease[]}} = yield call(makeApiRequest, { path: 'diseases' });
  if (!success) {
    throw new Error('Getting disease list is not successful');
  }
  return data;
}

export function* getJobStatus(jobId?: string) {
  const {
    json: {
      success,
      data
    }
  }: { json: { success: boolean; data: JobStatus } } = yield call(makeApiRequest, {
    path: 'admin/job/status',
    queryParams: { id: jobId },
  });

  if (!success) {
    throw new Error('Getting disease list is not success');
  }
  return data;
}

export function*  getJobParams() {
  const { json: { success, data } }: { json: { success: boolean; data: JobParams}} = yield call(makeApiRequest, { path: 'admin/job/params' });
  if (!success) {
    throw new Error('Getting job params is not successful');
  }
  return data;
}

export function* createJob(data: CreateJobParams) {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
  };
  const { json: { success, data: jobId } }: { json: { success: boolean; data: string}} = yield call(makeApiRequest, { path: 'admin/job', options });

  if (!success) {
    throw new Error('Creation job is not success');
  }
  return jobId;
}
