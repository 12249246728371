import * as React from 'react';
import {
  Edit,
  EditProps,
  TextInput,
  PasswordInput,
  Toolbar,
  FormWithRedirect,
  required,
  FieldProps,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  SelectArrayInput,
  TextField,
  ChipField,
  SaveButton,
  FormDataConsumer,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import Aside from './Aside';
import UserNameField from './UserNameField';
import { User } from '../types';
import { resourceNames } from '../constants';
import { makeStyles } from '@material-ui/core/styles';
import { AnyObject } from 'react-final-form';
import { UserFormSectionTitle } from './UserFormSectionTitle';

const useStyles = makeStyles({
  rolesIdsInput: { minWidth: '200px' },
  companyIdInput: { minWidth: '200px' },
});

const validatePasswords = ({ password, confirmPassword }: AnyObject) => {
  const errors = {} as any;

  if (password && password !== confirmPassword) {
    errors.confirmPassword = confirmPassword ? 'Password mismatch' : 'Required';
  }

  return errors;
};

export const UserEdit = (props: EditProps) => {
  return (
    <Edit
      aside={<Aside/>}
      component="div"
      title={<Title/>}
      {...props}
    >
      <EditForm/>
    </Edit>
  );
};

const Title = ({ record }: FieldProps<User>) =>
  record ? (
    <UserNameField
      record={record}
      size="32"
    />
  ) : null;

const EditForm = (props: any) => {
  const classes = useStyles();
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: 'block', lg: 'flex' }}>
                <Box
                  flex={2}
                  mr={{ md: 0, lg: '1em' }}
                >
                  <UserFormSectionTitle label="Identity"/>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      flex={1}
                      mr={{ xs: 0, sm: '0.5em' }}
                    >
                      <TextField source="email"/>
                    </Box>
                  </Box>

                  <Box mt="1em"/>

                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      flex={4}
                      mr={{ xs: 0, sm: '0.5em' }}
                    >
                      <TextField source="changePasswordUrl"/>
                    </Box>
                  </Box>

                  <Box mt="1em"/>

                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      flex={1}
                      mr={{ xs: 0, sm: '0.5em' }}
                    >
                      <TextInput
                        fullWidth
                        source="name"
                        validate={requiredValidate}
                      />
                    </Box>
                  </Box>

                  <UserFormSectionTitle label="Roles"/>
                  <Box display='block'>
                    <ReferenceArrayInput
                      reference={resourceNames.ADMIN_ROLES}
                      source="rolesIds"
                    >
                      <SelectArrayInput
                        className={classes.rolesIdsInput}
                        optionText="name"
                      >
                        <ChipField source="name"/>
                      </SelectArrayInput>
                    </ReferenceArrayInput>
                  </Box>

                  <UserFormSectionTitle label="Company"/>
                  <ReferenceInput
                    reference={resourceNames.ADMIN_COMPANIES}
                    source="companyId"
                  >
                    <SelectInput
                      className={classes.companyIdInput}
                      optionText="name"
                      validate={requiredValidate}
                    />
                  </ReferenceInput>

                  <UserFormSectionTitle label="Active"/>
                  <SelectInput
                    choices={[
                      { id: true, name: 'ON' },
                      { id: false, name: 'OFF' },
                    ]}
                    source="isActive"
                  />

                  <UserFormSectionTitle label="Reports"/>
                  <FormDataConsumer>
                    {({ formData }) => (
                      <ReferenceArrayInput
                        fullWidth
                        filter={{ companyId: formData.companyId }}
                        reference={resourceNames.ADMIN_REPORTS}
                        source="reportIds"
                      >
                        <SelectArrayInput
                          fullWidth
                          optionText="nctId"
                        >
                          <ChipField source="nctId"/>
                        </SelectArrayInput>
                      </ReferenceArrayInput>
                    )}
                  </FormDataConsumer>

                  <UserFormSectionTitle label="Sources"/>
                  <FormDataConsumer>
                    {({ formData }) => (
                      <ReferenceArrayInput
                        fullWidth
                        filter={{ companyId: formData.companyId }}
                        reference={resourceNames.ADMIN_HEALTH_PROVIDER_DEPARTMENTS}
                        source="healthProviderDepartmentIds"
                      >
                        <SelectArrayInput
                          fullWidth
                          optionText="name"
                        >
                          <ChipField source="id"/>
                        </SelectArrayInput>
                      </ReferenceArrayInput>
                    )}
                  </FormDataConsumer>

                  <Box mt="1em"/>

                  <UserFormSectionTitle label="Change password"/>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      flex={1}
                      mr={{ xs: 0, sm: '0.5em' }}
                    >
                      <PasswordInput
                        fullWidth
                        inputProps={{ autoComplete: 'new-password' }}
                        source="password"
                      />
                    </Box>
                    <Box
                      flex={1}
                      ml={{ xs: 0, sm: '0.5em' }}
                    >
                      <PasswordInput
                        fullWidth
                        inputProps={{ autoComplete: 'new-password' }}
                        source="confirmPassword"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </CardContent>
            <Toolbar>
              <SaveButton
                disabled={formProps.pristine}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                invalid={formProps.invalid}
                saving={formProps.saving}
              />
            </Toolbar>
          </form>
        </Card>
      )}
      validate={validatePasswords}
    />
  );
};

const requiredValidate = [required()];
