import * as React from 'react';
import { Link, FieldProps } from 'react-admin';

import { Doctor } from '../types';
import { resourceNames } from '../constants';

const DoctorLinkField = (props: FieldProps<Doctor>) =>
  props.record ? (
    <Link to={`/${resourceNames.ADMIN_DOCTORS}/${props.record.id}`}>
      <div>
        {props.record.first_name}
      </div>
    </Link>
  ) : null;

DoctorLinkField.defaultProps = {
  source: 'id',
  addLabel: true,
};

export default DoctorLinkField;
