import { call, delay, put, takeEvery, takeLeading, fork, take, cancel, takeLatest } from 'redux-saga/effects';
import { Task } from 'redux-saga';
import { showNotification, UNREGISTER_RESOURCE } from 'react-admin';
import { createJob, getDiseaseList, getJobParams, getJobStatus } from './api';
import {
  changeJobStatus,
  crateJobFailure,
  crateJobRequest,
  crateJobSuccess,
  CREATE_JOB,
  CREATE_JOB_SUCCESS,
  FETCH_JOB_DISEASE_LIST,
  FETCH_JOB_PARAMS,
  START_GENERAL_JOB_STATUS_SYNC,
  STOP_GENERAL_JOB_STATUS_SYNC,
  fetchDiseaseListFailure,
  fetchDiseaseListRequest,
  fetchDiseaseListSuccess,
  fetchJobParamsFailure,
  fetchJobParamsRequest,
  fetchJobParamsSuccess,
  changeGeneralStatus,
} from './actions';
import { JobStatuses } from './constants';
import { CreateJobParams, Disease, JobParams, JobStatus } from './types';

const UPDATE_JOB_STATUS_INTERVAL = 5000;
const UPDATE_GENERAL_STATUS_INTERVAL = 5000;
const UPDATE_GENERAL_STATUS_ERROR_SHOW_NOTIFICATION_LIMIT = 3;

function* createJobSaga() {
  yield takeLeading(CREATE_JOB, function* ({ payload }: { type: typeof CREATE_JOB; payload: CreateJobParams }) {
    yield put(crateJobRequest());
    try {
      const jobId: string = yield call(createJob, payload);

      yield put(crateJobSuccess({ id: jobId, data: payload }));
    } catch (err) {
      yield put(crateJobFailure(err));
      yield put(showNotification('Job creation failure', 'error'));
    }
  });
}

function* fetchDiseaseListSaga() {
  yield takeLeading(FETCH_JOB_DISEASE_LIST, function* () {
    yield put(fetchDiseaseListRequest());
    try {
      const result: Disease[] = yield call(getDiseaseList);

      yield put(fetchDiseaseListSuccess(result));
    } catch (err) {
      yield put(fetchDiseaseListFailure(err));
      yield put(showNotification('Fetching disease list failure', 'error'));
    }
  });
}

function* fetchJobParamsSaga() {
  yield takeLeading(FETCH_JOB_PARAMS, function* () {
    yield put(fetchJobParamsRequest());
    try {
      const result: JobParams = yield call(getJobParams);

      yield put(fetchJobParamsSuccess(result));
    } catch (err) {
      yield put(fetchJobParamsFailure(err));
      yield put(showNotification('Fetching job parameters failure', 'error'));
    }
  });
}

function* jobStatusPoll({ id: jobId, data }: { id: string; data: CreateJobParams }) {
  while (true) {
    yield delay(UPDATE_JOB_STATUS_INTERVAL);

    try {
      const { success }: JobStatus = yield call(getJobStatus, jobId);

      const isJobFulfilled = typeof success === 'boolean';
      if (isJobFulfilled) {
        const jobDetails = JSON.stringify(data);
        if (success) {
          yield put(changeJobStatus({ id: jobId, status: JobStatuses.SUCCESS }));
          yield put(showNotification(`Job id: "${jobId}" "${jobDetails}" successfully completed`));
        } else {
          yield put(changeJobStatus({ id: jobId, status: JobStatuses.FAILURE }));
          yield put(showNotification(`Job id: "${jobId}" "${jobDetails}" completed with an error`, 'error'));
        }
        return;
      }
    } catch (err) {
      // ignore
    }
  }
}

function* jobStatusPollSaga() {
  yield takeEvery(CREATE_JOB_SUCCESS, function* ({ payload }: {
    type: typeof CREATE_JOB_SUCCESS;
    payload: { id: string; data: CreateJobParams }
  }) {
    const { id: jobId } = payload;
    if (!jobId) {
      return;
    }

    const jobStatusPollTask: Task = yield fork(jobStatusPoll, payload);

    yield take(UNREGISTER_RESOURCE);

    // to stop on logout
    yield cancel(jobStatusPollTask);
  });
}

function* generalStatusPoll() {
  let errorCount = 0;
  while (true) {
    try {
      const result: JobStatus = yield call(getJobStatus);

      errorCount = 0;

      yield put(changeGeneralStatus(result));
    } catch (err) {
      ++errorCount;
      if (errorCount >= UPDATE_GENERAL_STATUS_ERROR_SHOW_NOTIFICATION_LIMIT) {
        errorCount = 0;
        yield put(showNotification('Getting last job status error', 'error'));
      }
    }

    yield delay(UPDATE_GENERAL_STATUS_INTERVAL);
  }
}

function* generalStatusPollSaga() {
  yield takeLatest(START_GENERAL_JOB_STATUS_SYNC, function* () {
    const task: Task = yield fork(generalStatusPoll);

    yield take(STOP_GENERAL_JOB_STATUS_SYNC);

    yield cancel(task);
  });
}

export const jobsSagas = [
  jobStatusPollSaga,
  createJobSaga,
  fetchDiseaseListSaga,
  fetchJobParamsSaga,
  generalStatusPollSaga,
];
