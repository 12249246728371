import * as React from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  FieldProps,
  Link,
  FunctionField,
} from 'react-admin';
import { Report } from '../types';
import { resourceNames } from '../constants';

const ReportLinkField = (props: FieldProps<Report>) => {
  return props.record ? (
    <Link to={`/${resourceNames.ADMIN_REPORTS}/${props.record.id}`}>
      <TextField source="id"/>
    </Link>
  ) : null;
};


const isActiveFieldRender = (record?: Report) => record && { 0: 'No', 1: 'Yes' }[record.isActive];

const IsActiveField = ({ record }: FieldProps<Report>) => (
  <FunctionField<Report>
    record={record}
    render={isActiveFieldRender}
  />
);

export const ReportsList = (props: ListProps) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      perPage={25}
      sort={{ field: 'id', order: 'asc' }}
    >
      <Datagrid>
        <ReportLinkField source="id"/>
        <TextField source="nctId"/>
        <TextField source="disease"/>
        <DateField source="minContractDate" />
        <DateField source="maxContractDate" />
        <IsActiveField source="isActive" />
      </Datagrid>
    </List>
  );
};
