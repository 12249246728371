import { Reducer, AnyAction } from 'redux';
import { UNREGISTER_RESOURCE } from 'react-admin';
import {
  FETCH_REPORTS_FOR_EXTEND_LIST_REQUEST,
  FETCH_REPORTS_FOR_EXTEND_LIST_SUCCESS,
  FETCH_REPORTS_FOR_EXTEND_LIST_FAILURE,
  EXTEND_REPORTS_PERIOD_REQUEST,
  EXTEND_REPORTS_PERIOD_SUCCESS,
  EXTEND_REPORTS_PERIOD_FAILURE,
  SET_SELECTED_NCTS_FOR_EXTEND_REPORTS,
  CLEAR_SELECTED_NCTS_FOR_EXTEND_REPORT,
  EXTEND_REPORTS_PERIOD_INTERRUPTED,
} from 'extendReportsPeriod/actions';
import { ReportsForExtend } from 'extendReportsPeriod/types';
import { QueryStatuses } from '../constants';

type State = ReportsForExtend;

const {
  PRISTINE,
  PENDING,
  FAILURE,
  SUCCESS,
} = QueryStatuses;

const initState: State = {
  reports: { status: PRISTINE, data: [], error: null },
  extendReportsPeriod: { status: PRISTINE, error: null, reportsForExclude: [] },
  selectedReportIds: [],
};

export const reportsForExtendReducer: Reducer<State, AnyAction> = (
  previousState = initState,
  action,
) => {
  const { payload } = action;
  switch (action.type) {
    case UNREGISTER_RESOURCE:
      return initState;
    case FETCH_REPORTS_FOR_EXTEND_LIST_REQUEST:
      return { ...previousState, reports: { ...previousState.reports, status: PENDING } };
    case FETCH_REPORTS_FOR_EXTEND_LIST_SUCCESS:
      return { ...previousState, reports: { status: SUCCESS, data: payload, error: null } };
    case FETCH_REPORTS_FOR_EXTEND_LIST_FAILURE:
      return { ...previousState, reports: { status: FAILURE, data: [], error: payload } };
    case EXTEND_REPORTS_PERIOD_REQUEST:
      return { ...previousState, extendReportsPeriod: { status: PENDING, error: null, reportsForExclude: [] } };
    case EXTEND_REPORTS_PERIOD_SUCCESS:
      return { ...previousState, extendReportsPeriod: { status: SUCCESS, error: null, reportsForExclude: [] } };
    case EXTEND_REPORTS_PERIOD_FAILURE:
      return { ...previousState, extendReportsPeriod: { status: FAILURE, error: payload, reportsForExclude: [] } };
    case EXTEND_REPORTS_PERIOD_INTERRUPTED:
      return { ...previousState, extendReportsPeriod: { status: FAILURE, error: null, reportsForExclude: payload } };
    case SET_SELECTED_NCTS_FOR_EXTEND_REPORTS:
      return { ...previousState, selectedReportIds: payload };
    case CLEAR_SELECTED_NCTS_FOR_EXTEND_REPORT:
      return { ...previousState, selectedReportIds: [] };
    default:
      return previousState;
  }
};
