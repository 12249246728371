import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  Modal,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { showNotification } from 'react-admin';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { format, compareAsc, previousSunday } from 'date-fns';
import { extendReportsPeriod } from 'extendReportsPeriod/actions';
import { AppState } from 'types';
import { QueryStatuses } from '../constants';

const TOP = 43;
const LEFT = 46;

const useStyles = makeStyles((theme: Theme) => (
  {
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '1px #000',
      padding: theme.spacing(2, 4, 3),
      top: `${TOP}%`,
      left: `${LEFT}%`,
      transform: `translate(-${TOP}%, -${LEFT}%)`,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    progress: {
      marginLeft: theme.spacing(1),
    },
  }));

export const ExtendPeriodModal = () => {
  const {
    data,
    status,
    selectedReportIds,
  } = useSelector(({ reportsForExtend }: AppState) => ({
    data: reportsForExtend.reports.data,
    selectedReportIds: reportsForExtend.selectedReportIds,
    status: reportsForExtend.extendReportsPeriod.status,
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [dateToUpdate, setDateToUpdate] = useState(() => format(previousSunday(Date.now()), 'yyyy-MM-dd'));
  const [isIncorrectDate, setIsIncorrectDate] = useState(false);

  const handleModalOpen = () => {
    if (selectedReportIds.length) {
      setOpen(true);
    } else {
      dispatch(showNotification('You need to select ncts', 'error'));
    }
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateValue = event.target.value;

    // check if the current date is less than the selected date
    if (compareAsc(Date.parse(dateValue), Date.now()) > 0) {
      dispatch(showNotification('Incorrect date', 'error'));
      setIsIncorrectDate(true);
    } else {
      setIsIncorrectDate(false);
    }

    setDateToUpdate(dateValue);
  };

  const extendPeriod = () => {
    const formattedDate = format(new Date(dateToUpdate).setHours(0, 0, 0, 0), 'yyyy-MM-dd HH:mm:ss');
    dispatch(extendReportsPeriod({ selectedReportIds, dateTo: formattedDate }));

    handleModalClose();
  };

  const showSelectedNcts = (reportIds: string[]) =>
    data.filter(({ reportId }) => reportIds.includes(reportId)).map(({ nctNumber }) => nctNumber);

  const isPending = status === QueryStatuses.PENDING;

  return (
    <div>
      <Grid
        container
        alignItems="center"
        direction="row"
        justifyContent="flex-end"
      >
        <Button
          color="primary"
          disabled={isPending}
          variant="contained"
          onClick={handleModalOpen}
        >
          Extend period
          { 
            isPending && (
              <CircularProgress
                className={classes.progress}
                size={16} 
              /> 
            )
          }
        </Button>
      </Grid>
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        open={open}
        onClose={handleModalClose}
      >
        <div className={classes.paper}>
          <h2 id="simple-modal-title">
            Selected nct's for extend
          </h2>
          <TextField
            className={classes.textField}
            error={isIncorrectDate}
            id="date"
            InputLabelProps={{
              shrink: true,
            }}
            label="Extend period date"
            type="date"
            value={dateToUpdate}
            onChange={handleDateChange}
          />
          <p id="simple-modal-description">
            {showSelectedNcts(selectedReportIds).join(', ')}
          </p>
          <Button 
            color="primary"
            disabled={isIncorrectDate}
            variant="contained"
            onClick={extendPeriod}
          >
            Update
          </Button>
        </div>
      </Modal>
    </div>
  );
};
