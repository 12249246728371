import { useEffect, useCallback, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CircularProgress,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import cx from 'classnames';
import { AppState } from '../types';
import { QueryStatuses } from '../constants';
import {
  fetchDiseaseList,
  fetchJobParams,
  crateJob,
  stopGeneralStatusSync,
  startGeneralStatusSync,
  resetGeneralStatus,
} from './actions';
import { CreateJobForm } from './CreateJobForm';
import { JobStatuses } from './constants';

const {
  PRISTINE: PRISTINE_QUERY_STATUS,
  SUCCESS: SUCCESS_QUERY_STATUS,
  FAILURE: FAILURE_QUERY_STATUS,
  PENDING: PENDING_QUERY_STATUS,
} = QueryStatuses;

const useStyles = makeStyles((theme: Theme) => {
  const { spacing } = theme;
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      fontSize: 36,
      padding: `${spacing(1)}px ${spacing(3)}px ${spacing(3)}px ${spacing(1)}px`,
    },
    startJobFormRoot: {
      flexShrink: 0,
      minWidth: '360px',
      padding: `${spacing(2)}px ${spacing(2)}px ${spacing(3)}px`,
    },
    secondColumn: {
      flexGrow: 12,
      display: 'flex',
      flexDirection: 'column',
      paddingTop: spacing(2),
    },
    jobStatusesRoot: {
      padding: `${spacing(2)}px ${spacing(2)}px ${spacing(3)}px`,
    },
    jobStatusesList: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
      fontSize: '14px',
    },
    jobStatusesListItem: {
      display: 'flex',
      marginTop: theme.spacing(2),
      padding: spacing(1),
      backgroundColor: theme.palette.grey['100'],
      '&:first-child': {
        marginTop: 0,
      }
    },
    jobStatusesJobIdColumn: {
      width: '120px',
    },
    jobStatusesDetailsColumn: {
      flexGrow: 12,
      marginLeft: spacing(1),
    },
    jobStatusesStatusColumn: {
      flexShrink: 0,
      marginLeft: spacing(1),
    },
    logsRoot: {
      marginTop: spacing(2),
      padding: `${spacing(2)}px ${spacing(2)}px ${spacing(3)}px`,
    },
    logsList: {
      margin: 0,
      listStyle: 'none',
      padding: 0,
      fontSize: '14px',
    },
    logsListItem: {
      marginTop: spacing(2),
      padding: spacing(1),
      backgroundColor: theme.palette.grey['100'],
      '&:first-child': {
        marginTop: 0,
      }
    },
    jobStatusesStatusMarker: {
      width: '32px',
      height: '16px',
    },
    jobStatusesPendingStatusMarker: {
      textAlign: 'center',
      backgroundColor: theme.palette.grey['500'],
    },
    jobStatusesSuccessStatusMarker: {
      backgroundColor: theme.palette.success.main,
    },
    jobStatusesFailureStatusMarker: {
      backgroundColor: theme.palette.error.main,
    },
    logsAccordion: {
      borderRadius: '10px',
      marginTop: spacing(2),
      '&.MuiAccordion-root:before': {
        display: 'none'
      }
    }
  };
});

export const Jobs = () => {
  const { diseases, params, jobCreation, jobStatuses, generalStatus } = useSelector((state: AppState) => {
    return state.jobs;
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isJobStatusClear, setIsJobStatusClear] = useState(false);

  const isJobCreationInProgress = jobCreation.status === PENDING_QUERY_STATUS;

  useEffect(() => {
    if ([PRISTINE_QUERY_STATUS, FAILURE_QUERY_STATUS].includes(diseases.status)) {
      dispatch(fetchDiseaseList());
    }
    if ([PRISTINE_QUERY_STATUS, FAILURE_QUERY_STATUS].includes(params.status)) {
      dispatch(fetchJobParams());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (generalStatus) {
      setIsJobStatusClear(true);
    }
  }, [generalStatus]);

  useEffect(() => {
    if (isJobCreationInProgress) {
      setIsJobStatusClear(false);
    }
  }, [isJobCreationInProgress]);

  useEffect(() => {
    dispatch(startGeneralStatusSync());
    return () => {
      dispatch(stopGeneralStatusSync());
      dispatch(resetGeneralStatus());
    };
  }, [dispatch]);

  const onCreateJobFormSubmit = useCallback((values: {diseases: string[]; job: string}) => {
    dispatch(crateJob({ name: values.job, diseases: values.diseases }));
  }, [dispatch]);

  const isFormSubmissionDisabled = isJobCreationInProgress
    || [diseases.status, params.status].some(i => i !== SUCCESS_QUERY_STATUS)
    || typeof generalStatus?.success !== 'boolean'
    || !isJobStatusClear;

  const diseasesByCode = new Map((diseases.data ?? []).map(disease => [disease.code, disease]));

  const jobList = params.data?.jobs ?? [];
  const diseaseList = params.data?.diseases?.map(diseaseCode => {
    const { name: diseaseName = diseaseCode } = diseasesByCode.get(diseaseCode) ?? {};
    return { value: diseaseCode, label: diseaseName };
  }) ?? [];

  const getStatusMarkerClassName = (status: JobStatuses) => {
    const statusMarkerByStatusValue = {
      [JobStatuses.PENDING]: classes.jobStatusesPendingStatusMarker,
      [JobStatuses.SUCCESS]: classes.jobStatusesSuccessStatusMarker,
      [JobStatuses.FAILURE]: classes.jobStatusesFailureStatusMarker,
    };
    // @ts-ignore
    return statusMarkerByStatusValue[status];
  };
  return (
    <div className={classes.root}>
      <Card className={classes.startJobFormRoot}>
        <CreateJobForm
          disabled={isFormSubmissionDisabled}
          diseaseList={diseaseList}
          jobList={jobList}
          loading={isJobCreationInProgress}
          onSubmit={onCreateJobFormSubmit}
        />
      </Card>
      <div className={classes.secondColumn}>
        <Card className={classes.jobStatusesRoot}>
          <ul className={classes.jobStatusesList}>
            {jobStatuses.map(({ id, data, status }) => {
              return (
                <li
                  className={classes.jobStatusesListItem}
                  key={id}
                >
                  <div className={classes.jobStatusesJobIdColumn}>
                    {id}
                  </div>
                  <div className={classes.jobStatusesDetailsColumn}>
                    {JSON.stringify(data)}
                  </div>
                  <div className={classes.jobStatusesStatusColumn}>
                    <div className={cx(classes.jobStatusesStatusMarker, getStatusMarkerClassName(status))}>
                      {status === JobStatuses.PENDING && (
                        <CircularProgress
                          size={16}
                          thickness={2}
                        />
                      )}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </Card>
        <Accordion className={classes.logsAccordion}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Logs</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul className={classes.logsList}>
              {(generalStatus?.logs ?? []).map((log, index) => (
                <li
                  className={classes.logsListItem}
                  key={`${generalStatus?.id} ${index}`}
                >
                  {log}
                </li>
              ))}
            </ul>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};
