import UserIcon from '@material-ui/icons/People';

import UserList from './UserList';
import { UserCreate } from './UserCreate';
import { UserEdit } from './UserEdit';

export const usersResource = {
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  icon: UserIcon,
};
