import * as React from 'react';
import { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { FieldProps } from 'react-admin';
import AvatarField from './AvatarField';
import { User } from '../types';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}));

interface Props extends FieldProps<User> {
  size?: string;
}

const UserNameField = (props: Props) => {
  const { record, size } = props;
  const classes = useStyles();
  return record ? (
    <div className={classes.root}>
      <AvatarField
        className={classes.avatar}
        record={record}
        size={size}
      />
      {record.name}
    </div>
  ) : null;
};

UserNameField.defaultProps = {
  source: 'name',
  label: 'Name',
};

export default memo<Props>(UserNameField);
