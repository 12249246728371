import { call, put } from 'redux-saga/effects';
import { stringify } from 'query-string';
import { userLogout } from 'react-admin';
import { httpClient } from 'httpClient';
import config from 'config';

const { apiUrl } = config;

export function* makeApiRequest({ path, queryParams = {}, options = {} }: {
  path: string;
  queryParams?: Record<string, any>;
  options?: {};
}) {
  try {
    const url = `${apiUrl}/${path}?${stringify(queryParams)}`;
    // @ts-ignore
    return yield call(httpClient, url, options);
  } catch (error: any) {
    const status = error.status;
    if (status === 401 || status === 419 || status === 403) {
      yield put(userLogout());
    }
    throw error;
  }
}
