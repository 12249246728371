import { CreateJobParams, JobStatus } from './types';
import { JobStatuses } from './constants';

export const FETCH_JOB_DISEASE_LIST = 'FETCH_JOB_DISEASE_LIST';
export const FETCH_JOB_DISEASE_LIST_REQUEST = 'FETCH_JOB_DISEASE_LIST_REQUEST';
export const FETCH_JOB_DISEASE_LIST_SUCCESS = 'FETCH_JOB_DISEASE_LIST_SUCCESS';
export const FETCH_JOB_DISEASE_LIST_FAILURE = 'FETCH_JOB_DISEASE_LIST_FAILURE';

export const FETCH_JOB_PARAMS = 'FETCH_JOB_PARAMS';
export const FETCH_JOB_PARAMS_REQUEST = 'FETCH_JOB_PARAMS_REQUEST';
export const FETCH_JOB_PARAMS_SUCCESS = 'FETCH_JOB_PARAMS_SUCCESS';
export const FETCH_JOB_PARAMS_FAILURE = 'FETCH_JOB_PARAMS_FAILURE';

export const CREATE_JOB = 'CREATE_JOB';
export const CREATE_JOB_REQUEST = 'CREATE_JOB_REQUEST';
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';
export const CREATE_JOB_FAILURE = 'CREATE_JOB_FAILURE';

export const CHANGE_JOB_STATUS = 'CHANGE_JOB_STATUS';

export const START_GENERAL_JOB_STATUS_SYNC = 'START_GENERAL_JOB_STATUS_SYNC';
export const STOP_GENERAL_JOB_STATUS_SYNC = 'STOP_GENERAL_JOB_STATUS_SYNC';
export const CHANGE_GENERAL_JOB_STATUS = 'CHANGE_GENERAL_JOB_STATUS';
export const RESET_GENERAL_JOB_STATUS = 'RESET_GENERAL_JOB_STATUS';

export const startGeneralStatusSync = () => {
  return { type: START_GENERAL_JOB_STATUS_SYNC };
};

export const stopGeneralStatusSync = () => {
  return { type: STOP_GENERAL_JOB_STATUS_SYNC };
};

export const changeGeneralStatus = (payload: JobStatus) => {
  return { type: CHANGE_GENERAL_JOB_STATUS, payload };
};

export const resetGeneralStatus = () => {
  return { type: RESET_GENERAL_JOB_STATUS };
};

export const fetchDiseaseList = () => {
  return { type: FETCH_JOB_DISEASE_LIST };
};

export const fetchDiseaseListRequest = () => {
  return { type: FETCH_JOB_DISEASE_LIST_REQUEST };
};

export const fetchDiseaseListSuccess = (data: {}) => {
  return { type: FETCH_JOB_DISEASE_LIST_SUCCESS, payload: data };
};

export const fetchDiseaseListFailure = (error: any) => {
  return { type: FETCH_JOB_DISEASE_LIST_FAILURE, payload: error };
};

export const fetchJobParams = () => {
  return { type: FETCH_JOB_PARAMS };
};

export const fetchJobParamsRequest = () => {
  return { type: FETCH_JOB_PARAMS_REQUEST };
};

export const fetchJobParamsSuccess = (data: {}) => {
  return { type: FETCH_JOB_PARAMS_SUCCESS, payload: data };
};

export const fetchJobParamsFailure = (error: any) => {
  return { type: FETCH_JOB_PARAMS_FAILURE, payload: error };
};

export const crateJob = (data: {}) => {
  return { type: CREATE_JOB, payload: data };
};

export const crateJobRequest = () => {
  return { type: CREATE_JOB_REQUEST };
};

export const crateJobSuccess = (payload: {id: string; data: CreateJobParams}) => {
  return { type: CREATE_JOB_SUCCESS, payload };
};

export const crateJobFailure = (error: any) => {
  return { type: CREATE_JOB_FAILURE, payload: error };
};

export const changeJobStatus = ({ id, status }: {id: string; status: JobStatuses}) => {
  return { type: CHANGE_JOB_STATUS, payload: { id, status } };
};
