import * as React from 'react';
import {
  Edit,
  EditProps,
  Toolbar,
  FormWithRedirect,
  SelectInput,
  SaveButton,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';

export const DoctorEdit = (props: EditProps) => {
  return (
    <Edit
      component="div"
      {...props}
    >
      <EditForm/>
    </Edit>
  );
};

const EditForm = (props: any) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: 'block', lg: 'flex' }}>
                <Box
                  flex={2}
                  mr={{ md: 0, lg: '1em' }}
                >

                  <SelectInput
                    choices={[
                      { id: '1', name: 'True' },
                      { id: '0', name: 'False' },
                    ]}
                    source="is_verified"
                  />
                </Box>
              </Box>
            </CardContent>
            <Toolbar>
              <SaveButton
                disabled={formProps.pristine}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                invalid={formProps.invalid}
                saving={formProps.saving}
              />
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};
