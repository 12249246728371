import {
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  SaveButton,
  SelectInput,
  Toolbar,
  DateInput,
} from 'react-admin';
import { ReportAside } from './ReportAside';
import * as React from 'react';
import { Box, Card, CardContent } from '@material-ui/core';
import { Report } from '../types';

export const ReportEdit = (props: EditProps) => {
  return (
    <Edit
      aside={<ReportAside/>}
      component="div"
      title={<Title/>}
      {...props}
    >
      <EditForm/>
    </Edit>
  );
};

const Title = ({ record }: FieldProps<Report>) => {
  return record ? (
    <div>
      {record.id}
      {' '}
      {record.nctId}
    </div>
  ) : null;
};

const EditForm = (props: any) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: 'block', lg: 'flex' }}>
                <Box
                  flex={2}
                  mr={{ md: 0, lg: '1em' }}
                >
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      flex={1}
                      mr={{ xs: 0, sm: '0.5em' }}
                    >
                      <DateInput source="minContractDate" />
                    </Box>
                  </Box>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      flex={1}
                      mr={{ xs: 0, sm: '0.5em' }}
                    >
                      <DateInput source="maxContractDate" />
                    </Box>
                  </Box>

                  <Box mt="1em"/>

                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      flex={1}
                      mr={{ xs: 0, sm: '0.5em' }}
                    >
                      <SelectInput
                        choices={[
                          { id: 1, name: 'Yes' },
                          { id: 0, name: 'No' },
                        ]}
                        source="isActive"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </CardContent>
            <Toolbar>
              <SaveButton
                disabled={formProps.pristine}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                saving={formProps.saving}
              />
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};
