import { Typography } from '@material-ui/core';
import * as React from 'react';

export const UserFormSectionTitle = ({ label }: { label: string }) => {
  return (
    <Typography
      gutterBottom
      variant="h6"
    >
      {label}
    </Typography>
  );
};
