import * as React from 'react';
import { Link, FieldProps } from 'react-admin';

import UserNameField from './UserNameField';
import { User } from '../types';
import { resourceNames } from '../constants';

const UserLinkField = (props: FieldProps<User>) =>
  props.record ? (
    <Link to={`/${resourceNames.ADMIN_USERS}/${props.record.id}`}>
      <UserNameField {...props} />
    </Link>
  ) : null;

UserLinkField.defaultProps = {
  source: 'userWixId',
  addLabel: true,
};

export default UserLinkField;
